// Google Maps Scripts
// When the window has finished loading create our google map below
if (typeof google === 'object' && typeof google.maps === 'object') {

    google.maps.event.addDomListener(window, 'load', init);

}

function init() {
    "use strict";
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: zoom_MAP,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng( lat_MAP, lng_MAP ), // You Address Here

        // Disables the default Google Maps UI components
        disableDefaultUI: disableDefaultUI_MAP,
        scrollwheel: scrollwheel_MAP,
        draggable: draggable_MAP,

        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles:
            [{"stylers":[{"saturation":-100},{"gamma":1}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"on"},{"saturation":-35},{"gamma":8},{"hue":"#f7f7f7"}]},{"featureType":"administrative.neighborhood","elementType":"labels.text.fill","stylers":[{"color":"#333333"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"weight":0.5},{"color":"#333333"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"hue": "#0008ff"},{"lightness": "8"},{"saturation": "-20"}]}]
    };

    // Get the HTML DOM element that will contain your map
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map');

    // Create the Google Map using out element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    // Custom Map Marker Icon - Customize the map-marker.png file to customize your icon
    var image = marker_MAP;
    var myLatLng = new google.maps.LatLng( lat_MAP, lng_MAP ); // You Address Here
    var beachMarker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        icon: image
    });
}